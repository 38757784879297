import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogAboutPreventiveDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const preventive_dentistry_img = data.preventive_dentistry_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Everything you need to know about Preventive Dentistry</title>
      <meta name="description" content="Preventive dentistry is dental care that aims to preserve good oral health. Here is everything you need to know about preventive dentistry." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Preventive dentistry is dental care that aims to preserve good oral health. Here is everything you need to know about preventive dentistry." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Preventive dentistry is dental care that aims to preserve good oral health. Here is everything you need to know about preventive dentistry." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Preventive Dentistry
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2021/11/06
                </li>
                <li className="list-inline-item badge badge-primary">
                  Preventive Dentistry
                </li>
                <li className="list-inline-item badge badge-secondary">
                  Everything You Need To Know About Preventive Dentistry
                </li>
              </ul>
                <Img fluid={preventive_dentistry_img} className="blog-post-img"/>
              <h2 className="mb-3">
                Everything You Need To Know About Preventive Dentistry
              </h2>
              <p>
                In this post we will be going over preventive dentistry and what it means for you as a patient. First of all, what is it?
              </p>
              <p>
                Preventive dentistry is dental care that aims to preserve good oral health. It's made up of two parts: regular dental check-ups and regular healthy practices like brushing and flossing your teeth. Your teeth are the crowning glory of your body, and they deserve to be taken care of from birth. They are something you should worry about for the rest of your life.
              </p>
              <p>
                Every day, you use your teeth while eating, talking, and smiling. Teeth and gums that are not cared for over time are subjected to serious concerns such as decay and infection. When they are detected early enough, we can take precautions to prevent some of these concerns from developing into major pains - literally - and not just a toothache. Many of the procedures we perform on teeth that have been neglected often cost more than routine maintenance.
              </p>
              <p>
                Regular care and preventive dentistry is something that can save you much time, money, pain, and hassle.
              </p>
              <h3 className="mb-3">
                Preventive dental treatments may include:
              </h3>
              <p>
                - Regular dental check-ups, on average every six months
              </p>
              <p>
                - Teeth cleaning
              </p>
              <p>
                - X-rays
              </p>
              <p>
                - Oral cancer screening
              </p>
              <p>
                Depending on the sort of dental insurance you have, your dental plan may cover all of your preventive care.
              </p>
              <p>
                You are responsible for a large portion of your preventative dental treatment. It's possible to develop good dental habits at an early age that can help prevent cavities, gum disease, and gingivitis.
              </p>
              <h3 className="mb-3">
                The following are some of the most essential elements of great oral health:
              </h3>
              <p>
                Brush your teeth at least twice a day—typically in the morning and at night—with a soft-bristled brush and fluoride toothpaste.
              </p>
              <p>
                To avoid plaque from developing, floss daily to remove food that has become trapped between your teeth.
              </p>
              <p>
                After flossing, rinse with a mouthwash. Mouthwash should not be used as a replacement for brushing and flossing!
              </p>
              <p>
                Acidic foods should be avoided since they can erode tooth enamel.
              </p>
              <p>
                Be careful with hard foods, such as certain sweets, food with bones, seeds, or pits, which might harm or chip teeth.
              </p>
              <p>
                Smoking and other tobacco use is harmful for your teeth and gums. They can also lead to various types of cancer. Avoid using them.
              </p>
              <p>
                You may want to wear a mouthguard when playing sports to help protect your teeth.
              </p>
              <p>
                A general dentist (or a pediatric dentist for children) can provide you with more direction and additional help for preventive dentistry.
              </p>
              <p>
                A general dentist is one who specializes in all types of dental work. They may also be referred to as a family dentist. In the event that you want routine preventative care such as regular teeth cleanings, check-ups, and X-rays, a general or family dentist will work great. One of your dentist's most important responsibilities is to educate you in the best dental care for yourself. They can detect any early warning signs so you don't have to, help you establish healthier routines, and suggest dental specialists if extra treatment outside of their scope is required.
              </p>
              <p>
                Are you looking for a general dentist to help you stay on top of preventive dental care? If so, you have come to the right place. The Dentists at Gateway Crossing can help you. Call us today to schedule your appointment!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogAboutPreventiveDentistryPage

export const query = graphql`
  query BlogAboutPreventiveDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    preventive_dentistry_img: file(name: { eq: "cleanings-and-preventive-care" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
